import React, { useRef, useState, useEffect } from "react"
import { Table, Modal, Button, Input, Space } from "antd"
import { handleNestedObjectKey } from 'utils'
import { SearchOutlined } from "@ant-design/icons"
import _ from 'lodash'

const ListOptions = (props) => {
  const { open, onCancel, columns, dataSource, onSubmit, selectedKeys } = props.item
  const [selectedValues, setSelectedValues] = useState(handleNestedObjectKey(props, selectedKeys))

  const searchInput = useRef(null);

  const handleReset = ( clearFilters,confirm ) => {
    clearFilters();
    confirm();
  };
  
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        className="p-3"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search...`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            onClick={() => confirm()}
            className= 'mr-2'
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset( clearFilters, confirm)}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined />
    ),
    onFilter: (value, record) => handleNestedObjectKey(record, dataIndex)?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  useEffect(() => {
    setSelectedValues(handleNestedObjectKey(props, selectedKeys));
  }, [handleNestedObjectKey(props, open)]);

  return (
    <Modal open={handleNestedObjectKey(props, open)} width={800} onCancel={() => props.onClickAction( onCancel )} footer={null} destroyOnClose>
      <Table
        rowKey="id"
        className="mt-4"
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedValues.keys,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedValues({keys: selectedRowKeys, rows: selectedRows})
          },
        }}
        columns={
          handleNestedObjectKey(props, columns)?.map(column => {            
            return {
              ...column,
              ...(column.filterItem ? getColumnSearchProps(column.dataIndex) : {})
            };
          }) || [
            {
              title: "Options",
              dataIndex: "label",             
            },
          ]
        }
        dataSource={handleNestedObjectKey(props, dataSource) || []}
        
      />
      <div className="d-flex justify-content-end">
        <Button onClick={()=>{props.onClickAction( onSubmit, selectedValues )}}>Add</Button>
      </div>
    </Modal>
  )
}

export default ListOptions
