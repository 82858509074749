import React, { Suspense, useState, useEffect } from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { Route, Switch, withRouter, Redirect } from "react-router-dom"
import _ from "lodash"

import { applyColor } from 'utils/apply-color'
import permissionsChecker from 'utils/permissionChecker'
import TemplateContainerMain from "components/Template"
import TemplateHeader from "components/Header"
import TemplateSidemenu from "components/Menu/Sidemenu"
import LoadingOverlay from "components/Indicator/LoadingOverlay"
import TermsAndConditions from 'components/TermsAndConditions'
import TechBaseFooter from "DisplayCore/Footer"
import KYCSubmission from "../KYCSubmission"
import KYCStatusModal from '../KYCSubmission/statusModal'
import { routes } from './routes'

const SuspenseWrapper = ({ children }) => <Suspense fallback={<LoadingOverlay />}>{children}</Suspense>

const Dashboard = props => {
  const [ showKYC, updateShowKYC ] = useState( false )
  const [ showStatusModal, updateShowStatusModal ] = useState( false )

  useEffect( () => {
    if ( props.KYCRoleConfig.includes( props.profile.role_name ) ) {
      if ( props.profile.status === 'pending' ) {
        updateShowKYC( true )
      } else if ( props.profile.status === 'verification-failed' ) {
        updateShowStatusModal( true )
      } else {
        updateShowKYC( false )
        updateShowStatusModal( false )
      }
    } else {
      updateShowKYC( false )
      updateShowStatusModal( false )
    }
  }, [ props.profile.status, props.KYCRoleConfig, props.profile.role_name ] )

  const getSiteModule = ( moduleName, siteModules ) => {
    return _.find( siteModules, { name: moduleName } )
  }

  const getPermissions = ( moduleNames, siteModules ) => {
    if( _.isUndefined( moduleNames ) ){
      return {
        can_read: true,
        can_update: true,
        can_delete: true,
        can_create: true
      }
    }
    let tmpModule = getSiteModule( moduleNames[0], siteModules )
    let tmpPermission = permissionsChecker( tmpModule?.id, props.permissions )
    if( moduleNames.length > 1 && tmpPermission.can_read ){
      return getPermissions( moduleNames.slice(1), tmpModule.submodules )
    }
    return tmpPermission
  }

  return (
    <TemplateContainerMain>
      <TemplateHeader history={props.history} windowHash={ window.location.hash }/>
      <div className={ `app-main ${ props.clientInfo.name }` }>
        <TemplateSidemenu 
          userPermissions={props.userPermissions} 
          user={props.user} 
          userRole={props.userRole} />
        <div className="app-main__outer" style={{ backgroundColor: applyColor( '$secondary', props.colors ) }}>
          <div className='app-main__inner'>
            <KYCSubmission 
              showKYC={ showKYC } 
              updateShowKYC={ updateShowKYC } 
              status={ props.profile.status } 
              updateShowStatusModal={ updateShowStatusModal }
            />
            <KYCStatusModal
              data={ props.data }
              colors={ props.colors }
              updateShowKYC={ updateShowKYC }
              showStatusModal={ showStatusModal }
              updateShowStatusModal={ updateShowStatusModal }
            />
            <SuspenseWrapper>
              <Switch>
                {
                  routes.map( route => {
                    let tmpPermissions = getPermissions( route.module_name?.split('.'), props.siteModules )
                    if( tmpPermissions.can_read ){
                      return(
                        <Route 
                          exact={ route.exact }
                          path={ route.path }   
                          render={ props => route.render( { ...props,  modulePermissions: tmpPermissions } ) }
                        />
                      )
                    }
                  })
                }
                <Redirect from={"/dashboard/*"} to={"/dashboard"} />
              </Switch>
            </SuspenseWrapper>
          </div>
          <TechBaseFooter data={ props.data }/>
        </div>
      </div>
      {
        props.loadingContent.length === 0 && props.siteInfo.terms_and_conditions_content && (
          <TermsAndConditions isOpen={ !_.isEmpty(props.profile) && _.isNull(props.profile.date_accepted_tnc) }/>
        )
      }
    </TemplateContainerMain>
  )
}

const mapStateToProps = state => ({ 
  data: state,
  profile: state.ProfileReducer.profile,
  siteInfo: state.ClientReducer.siteInfo,
  clientInfo: state.ClientReducer.clientInfo,
  colors: state.ClientReducer.clientInfo.styles?.colors,
  KYCRoleConfig: state.ClientReducer.clientInfo.client_overall_config?.showKYC.roles,
  siteModules: state.ClientReducer.siteModules,
  permissions: state.ProfileReducer.permissions,
  loadingContent: state.LoadingOverlayReducer.loadingContent,
})

export default compose( 
  withRouter,
  connect( mapStateToProps )
)( Dashboard )
