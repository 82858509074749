import ContactUs from "containers/LBB/ContactUs"
import React, { lazy } from "react"

const Mall = lazy(() => import("./Mall/List"))
const ProductDetails = lazy(() => import("./Mall/ProductDetails"))
const PackageDetails = lazy(() => import("./Mall/PackageDetails"))
const PackageManagment = lazy(() => import("./ProductsManagement/Packages"))
const MainContent = lazy(() => import("./MainContent"))
const ProductsManagement = lazy(() => import("./ProductsManagement/Products"))
const ProductsCategory = lazy(() => import("./ProductsManagement/ProductsCategory"))
const ProductsTags = lazy(() => import("./ProductsManagement/ProductsTags"))
const CheckoutCodes  = lazy(() => import("./ProductsManagement/CheckoutCodes"))
const MemberList = lazy(() => import("./Users/MemberList"))
const UpdateProfile = lazy(() => import("./Users/UpdateProfile"))
const DownloadCP58 = lazy(() => import("./CP58/DownloadCP58")) 
const WarehousesManagement = lazy(() => import("./WarehousesManagement"))
const TransactionApproval = lazy(() => import("./TransactionApproval"))
const ShipmentZoneAndPricing = lazy(() => import("./SystemSettings/ShipmentZoneAndPricing"))
const Checkout = lazy(() => import("./Checkout"))
const RolePermissions = lazy(() => import( './StaffSettings/RolePermissions' ))
const SubadminList = lazy(() => import( './StaffSettings/SubadminList' ))
const SponsorTree = lazy(() => import("./OrganisationChart/SponsorTree"))
const Orders = lazy(() => import("./Orders"))
const Wallet = lazy(() => import("./WalletStatement"))
const WalletReload = lazy(() => import("./WalletReload"))
const WalletTransfer = lazy(() => import("./WalletTransfer"))
const WalletConvert = lazy(() => import("./WalletConvert"))
const WalletWithdrawal = lazy(() => import("./WalletWithdrawal"))
const PlacementTree = lazy(() => import("./OrganisationChart/PlacementTree"))
const ShipmentDispenseList = lazy(() => import("./Shipment/DispenseList"))
const ShipmentSelfCollectionList = lazy(() => import("./Shipment/SelfCollectionList"))
const ShipmentShippingList = lazy(() => import("./Shipment/ShippingList"))
const CommissionsDetailReport = lazy(() => import( './Reports/CommissionsDetailReport' ))
const CommissionSummaryReport = lazy(() => import("./Reports/CommissionSummaryReport"))
const SalesSummaryReport = lazy(() => import("./Reports/SalesSummaryReport"))
const SalesReport = lazy(() => import("./Reports/SalesReport"))
const SalesDetailReport = lazy(() => import("./Reports/SalesDetailReport"))
const NonSalesReport = lazy(() => import("./Reports/NonSalesReport"))
const PaymentSuccessful = lazy(() => import("./Checkout/PaymentSuccessful"))
const PaymentFailed = lazy(() => import("./Checkout/PaymentFailed"))
const PaymentPending = lazy(() => import("./Checkout/PaymentPending"))
const ReloadPending = lazy(() => import("./WalletReload/ReloadPending"))
const ReloadSuccessful = lazy(() => import("./WalletReload/ReloadSuccessful"))
const ReloadFailed = lazy(() => import("./WalletReload/ReloadFailed"))
const AnnouncementList = lazy(() => import("./Announcement/AnnouncementManagement"))
const StockAdjustmentList =  lazy(() => import("./Inventory/StockAdjustmentList"))
const StockAdjustmentReport =  lazy(() => import("./Inventory/StockAdjustmentReport"))
const InventoryPerpetualList =  lazy(() => import("./Inventory/InventoryPerpetualList"))
const ReloadAndUpgradeMall =  lazy(() => import( './ReloadAndUpgradeMall' ))
const BannerList = lazy(() => import( './SystemSettings/BannerList' ))
const BonusSummary = lazy(() => import( './Reports/CommissionsReport/BonusSummary' ))
const DailyPairedHistory = lazy(() => import( './Reports/CommissionsReport/DailyPairedHistory' ))
const MonthlyPairedHistory = lazy(() => import( './Reports/CommissionsReport/MonthlyPairedHistory' ))
const PairingDailyBalance = lazy(() => import( './Reports/CommissionsReport/PairingDailyBalance' ))
const SalesSVToMaintenance = lazy(() => import( './Reports/CommissionsReport/SalesSVToMaintenance' ))
const UpgradePackageRankHistory = lazy(() => import( './Reports/CommissionsReport/UpgradePackageRankHistory' ))
const UpgradeRankHistory = lazy(() => import( './Reports/CommissionsReport/UpgradeRankHistory' ))
const SVBalance = lazy(() => import( './Reports/CommissionsReport/SVBalance' ))
const AllTimeSalesSV = lazy(() => import( './Reports/CommissionsReport/AllTimeSalesSV' ))
const SumMemberRewardWallet = lazy(() => import( './Reports/CommissionsReport/SumMemberRewardWallet' ))
const PlacementDownPairDetails = lazy(() => import( './Reports/CommissionsReport/PlacementDownPairDetails' ))
const Voucher = lazy(() => import('./Voucher'))
const CompanyInformation = lazy(() => import('./CompanyInformation'))
const OfflineRewards  = lazy(() => import('./CP58/OfflineRewards'))
const Discounts = lazy(() => import('./Promotions/Discounts'))
const PWP = lazy(() => import('./Promotions/PWP'))
const Vouchers = lazy(() => import('./Promotions/Vouchers'))
const PromoGroup = lazy(() => import('./Promotions/PromoGroups'))
const ProductsList = lazy(() => import( './ProductsManagement/ProductsList' ))
const StocksList = lazy(() => import( './ProductsManagement/StocksList' ))

export const routes = [
  {
    exact: true,
    module_name: "Dashboard",
    path: "/dashboard",
    render: (props) => <MainContent {...props} /> 
  },
  {
    exact: true,
    module_name: "Products Management.Products Per Group",
    path: "/dashboard/products-management",
    render: (props) => <ProductsManagement {...props} /> 
  },
  {
    exact: true,
    module_name: 'Products Management.Product List',
    path: '/dashboard/products-list',
    render: (props) => <ProductsList {...props} />
  },
  {
    exact: true,
    module_name: 'Products Management.Stock List From PC',
    path: '/dashboard/stocks-list',
    render: (props) => <StocksList {...props} />
  },
  {
    exact: true,
    module_name: "Products Management.Products Category",
    path: "/dashboard/products-category",
    render: (props) => <ProductsCategory {...props} /> 
  },
  {
    exact: true,
    module_name: "Products Management.Products Tags",
    path: "/dashboard/products-tags",
    render: (props) => <ProductsTags {...props} /> 
  },
  {
    exact: true,
    module_name: "Products Management.Packages",
    path: "/dashboard/packages-management",
    render: (props) => <PackageManagment {...props} /> 
  },
  {
    exact: true,
    module_name: "Products Management.Checkout Codes",
    path: "/dashboard/checkout-code",
    render: (props) => <CheckoutCodes {...props} /> 
  },
  {
    exact: true,
    module_name: "Voucher Management",
    path: "/dashboard/voucher",
    render: (props) => <Voucher {...props} /> 
  },
  {
    exact: true,
    module_name: "Users.Profile",
    path: "/dashboard/update-profile",
    render: (props) => <UpdateProfile {...props} /> 
  },
  {
    exact: true,
    module_name: "Users.Member List",
    path: "/dashboard/member-list",
    render: (props) => <MemberList {...props} /> 
  },
  {
    exact: true,
    module_name: "Users.Download CP58",
    path: "/dashboard/download-cp58",
    render: (props) => <DownloadCP58 {...props} /> 
  },
  {
    exact: true,
    module_name: "CP58.Download CP58",
    path: "/dashboard/download-cp58",
    render: (props) => <DownloadCP58 {...props} /> 
  },
  {
    exact: true,
    module_name: "CP58.Offline Reward",
    path: "/dashboard/member-offline-rewards",
    render: (props) => <OfflineRewards {...props} /> 
  },
  {
    exact: true,
    module_name: "Mall.Shopping Mall",
    path: "/dashboard/mall",
    render: (props) => <Mall {...props} /> 
  },
  {
    exact: false,
    module_name: "Mall.Shopping Mall",
    path: "/dashboard/products/*",
    render: (props) => <ProductDetails {...props} /> 
  },
  {
    exact: false,
    module_name: "Mall.Shopping Mall",
    path: "/dashboard/packages/*",
    render: (props) => <PackageDetails {...props} /> 
  },
  {
    exact: true,
    module_name: "Mall.Upgrade Mall",
    path: "/dashboard/upgrade/mall",
    render: (props) => <Mall {...props} /> 
  },
  {
    exact: false,
    module_name: "Mall.Upgrade Mall",
    path: "/dashboard/upgrade/products/*",
    render: (props) => <ProductDetails {...props} /> 
  },
  {
    exact: false,
    module_name: "Mall.Upgrade Mall",
    path: "/dashboard/upgrade/packages/*",
    render: (props) => <PackageDetails {...props} /> 
  },
  {
    exact: true,
    module_name: "Mall.Redemption Mall",
    path: "/dashboard/redemption/mall",
    render: (props) => <Mall {...props} /> 
  },
  {
    exact: false,
    module_name: "Mall.Redemption Mall",
    path: "/dashboard/redemption/products/*",
    render: (props) => <ProductDetails {...props} /> 
  },
  {
    exact: false,
    module_name: "Mall.Redemption Mall",
    path: "/dashboard/redemption/packages/*",
    render: (props) => <PackageDetails {...props} /> 
  },
  {
    exact: true,
    module_name: "Warehouses",
    path: "/dashboard/warehouses-management",
    render: (props) => <WarehousesManagement {...props} /> 
  },
  {
    exact: true,
    module_name: "Transactions Approval",
    path: "/dashboard/transaction-approval",
    render: (props) => <TransactionApproval {...props} /> 
  },
  {
    exact: true,
    module_name: "System Settings.Shipment Zones and Pricings",
    path: "/dashboard/shipment-zone-and-pricings",
    render: (props) => <ShipmentZoneAndPricing {...props} /> 
  },
  {
    exact: true,
    module_name: 'System Settings.Banner List',
    path: '/dashboard/banner-list',
    render: (props) => <BannerList {...props} />
  },
  {
    exact: true,
    module_name: "Mall.Shopping Mall",
    path: "/dashboard/checkout",
    render: (props) => <Checkout {...props} /> 
  },
  {
    exact: true,
    module_name: "Mall.Upgrade Mall",
    path: "/dashboard/upgrade/checkout",
    render: (props) => <Checkout {...props} /> 
  },
  {
    exact: true,
    module_name: "Mall.Redemption Mall",
    path: "/dashboard/redemption/checkout",
    render: (props) => <Checkout {...props} /> 
  },
  {
    exact: true,
    path: "/dashboard/checkout/payment_successful/*",
    render: (props) => <PaymentSuccessful {...props} /> 
  },
  {
    exact: true,
    path: "/dashboard/checkout/payment_failed/*",
    render: (props) => <PaymentFailed {...props} /> 
  },
  {
    exact: true,
    path: "/dashboard/checkout/payment_pending/*",
    render: (props) => <PaymentPending {...props} /> 
  },
  {
    exact: true,
    module_name: 'Staff Settings.Roles and Permission',
    path: '/dashboard/role_permission',
    render: (props) => <RolePermissions {...props} />
  },
  {
    exact: true,
    module_name: 'Staff Settings.Subadmin List',
    path: '/dashboard/subadmin-list',
    render: (props) => <SubadminList {...props} />
  },
  {
    exact: true,
    module_name: "Staff Settings.Company Information",
    path: "/dashboard/company-information", 
    render: (props) => <CompanyInformation {...props} /> 
  },
  {
    exact: true,
    module_name: "Organisation Chart.Sponsor Tree",
    path: "/dashboard/sponsor_tree",
    render: (props) => <SponsorTree {...props} /> 
  },
  {
    exact: true,
    module_name: "Organisation Chart.Placement Tree",
    path: "/dashboard/placement_tree",
    render: (props) => <PlacementTree {...props} /> 
  },
  {
    exact: true,
    module_name: "Orders",
    path: "/dashboard/orders",
    render: (props) => <Orders {...props} /> 
  },
  {
    exact: true,
    module_name: "Wallets.Wallet Statement",
    path: "/dashboard/wallets",
    render: (props) => <Wallet {...props} /> 
  },
  {
    exact: true,
    module_name: "Wallets.Reload",
    path: "/dashboard/wallet-topup",
    render: (props) => <WalletReload {...props} /> 
  },
  {
    exact: true,
    module_name: "Wallets.Transfer",
    path: "/dashboard/wallet-transfer",
    render: (props) => <WalletTransfer {...props} /> 
  },
  {
    exact: true,
    module_name: "Wallets.Convert",
    path: "/dashboard/wallet-convert",
    render: (props) => <WalletConvert {...props} /> 
  },
  {
    exact: true,
    module_name: "Wallets.Withdrawal",
    path: "/dashboard/wallet-withdrawal",
    render: (props) => <WalletWithdrawal {...props} /> 
  },
  {
    exact: true,
    module_name: "Wallets.Reload",
    path: "/dashboard/wallet/reload_successful/*",
    render: (props) => <ReloadSuccessful {...props} /> 
  },
  {
    exact: true,
    module_name: "Wallets.Reload",
    path: "/dashboard/wallet/reload_pending/*",
    render: (props) => <ReloadPending {...props} /> 
  },
  {
    exact: true,
    module_name: "Wallets.Reload",
    path: "/dashboard/wallet/reload_failed/*",
    render: (props) => <ReloadFailed {...props} /> 
  },
  {
    exact: true,
    module_name: "Shipment.Dispense List",
    path: "/dashboard/dispense-list",
    render: (props) => <ShipmentDispenseList {...props} /> 
  },
  {
    exact: true,
    module_name: "Shipment.Self Collection List",
    path: "/dashboard/self-collection-list",
    render: (props) => <ShipmentSelfCollectionList {...props} /> 
  },
  {
    exact: true,
    module_name: "Shipment.Shipping List",
    path: "/dashboard/shipping-list",
    render: (props) => <ShipmentShippingList {...props} /> 
  },
  {
    exact: true,
    module_name: 'Reports.Commissions Detail Report',
    path: '/dashboard/commissions-detail-report',
    render: (props) => <CommissionsDetailReport { ...props } />
  },
  {
    exact: true,
    module_name: 'Reports.Commissions Report.Bonus Summary',
    path: '/dashboard/bonus-summary',
    render: ( props ) => <BonusSummary { ...props } />
  },
  {
    exact: true,
    module_name: 'Reports.Commissions Report.Daily Paired History',
    path: '/dashboard/daily-paired-history',
    render: ( props ) => <DailyPairedHistory { ...props } />
  },
  {
    exact: true,
    module_name: 'Reports.Commissions Report.Monthly Paired History',
    path: '/dashboard/monthly-paired-history',
    render: ( props ) => <MonthlyPairedHistory { ...props } />
  },
  {
    exact: true,
    module_name: 'Reports.Commissions Report.Pairing Daily Balance',
    path: '/dashboard/pairing-daily-balance',
    render: ( props ) => <PairingDailyBalance { ...props } />
  },
  {
    exact: true,
    module_name: 'Reports.Commissions Report.Sales SV To Maintenance',
    path: '/dashboard/sales-to-maintence',
    render: ( props ) => <SalesSVToMaintenance { ...props } />
  },
  {
    exact: true,
    module_name: 'Reports.Commissions Report.Upgrade Package Rank History',
    path: '/dashboard/upgrade-package-rank-history',
    render: ( props ) => <UpgradePackageRankHistory { ...props } />
  },
  {
    exact: true,
    module_name: 'Reports.Commissions Report.Upgrade Rank History',
    path: '/dashboard/upgrade-rank-history',
    render: ( props ) => <UpgradeRankHistory { ...props } />
  },
  {
    exact: true,
    module_name: 'Reports.Commissions Report.SV Balance',
    path: '/dashboard/sv-balance',
    render: ( props ) => <SVBalance { ...props } />
  },
  {
    exact: true,
    module_name: 'Reports.Commissions Report.All Time Sales SV',
    path: '/dashboard/all-times-sales',
    render: ( props ) => <AllTimeSalesSV { ...props } />
  },
  {
    exact: true,
    module_name: 'Reports.Commissions Report.Placement Down Pair Details',
    path: '/dashboard/placement-down-pair-details',
    render: ( props ) => <PlacementDownPairDetails { ...props } />
  },
  {
    exact: true,
    module_name: "Reports.Commission Summary Report",
    path: "/dashboard/commission-summary",
    render: (props) => <CommissionSummaryReport {...props} /> 
  },
  {
    exact: true,
    module_name: "Reports.Sales Summary Report",
    path: "/dashboard/sales-summary-report",
    render: (props) => <SalesSummaryReport {...props} /> 
  },
  {
    exact: true,
    module_name: "Reports.Sales Report",
    path: "/dashboard/sales-report",
    render: (props) => <SalesReport {...props} /> 
  },
  {
    exact: true,
    module_name: "Reports.Sales Detail Report",
    path: "/dashboard/sales-detail-report",
    render: (props) => <SalesDetailReport {...props} /> 
  },
  {
    exact: true,
    module_name: "Reports.Non Sales Report",
    path: "/dashboard/non-sales-report",
    render: (props) => <NonSalesReport {...props} /> 
  },
  {
    exact: true,
    module_name: "Announcements",
    path: "/dashboard/announcements",
    render: (props) => <AnnouncementList {...props} /> 
  },
  {
    exact: true,
    module_name: "Inventory.Stock Adjustment List",
    path: "/dashboard/stock-adjustment-list",
    render: (props) => <StockAdjustmentList {...props} /> 
  },
  {
    exact: true,
    module_name: "Inventory.Stock Adjustment Report",
    path: "/dashboard/stock-adjustment-report",
    render: (props) => <StockAdjustmentReport {...props} /> 
  },
  {
    exact: true,
    module_name: "Inventory.Inventory Perpetual List",
    path: "/dashboard/inventory-perpetual-list",
    render: (props) => <InventoryPerpetualList {...props} /> 
  },
  {
    exact: true,
    module_name: 'Mall',
    path: '/dashboard/reload-and-upgrade-mall',
    render: ( props ) => <ReloadAndUpgradeMall { ...props } />
  },
  {
    exact: true,
    module_name: 'Contact Us',
    path: '/dashboard/contact-us',
    render: ( props ) => <ContactUs { ...props } />
  },
  {
    exact: true,
    module_name: 'Promotions.Discounts',
    path: '/dashboard/promotions/discounts',
    render: ( props ) => <Discounts { ...props } />
  },
  {
    exact: true,
    module_name: 'Promotions.PWP',
    path: '/dashboard/promotions/pwp',
    render: ( props ) => <PWP { ...props } />
  },
  {
    exact: true,
    module_name: 'Promotions.Vouchers',
    path: '/dashboard/promotions/vouchers',
    render: ( props ) => <Vouchers { ...props } />
  },
  {
    exact: true,
    module_name: 'Promotions.Promo Groups',
    path: '/dashboard/promotions/promoGroups',
    render: ( props ) => <PromoGroup { ...props } />
  }
]
