import React from "react"
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import { Form, Input, Button } from "antd"

import { getSelectedLanguageContent } from "utils"

const FormList = (props) => {
  const { name, rules, style, label, className, inputConfig, placeholder, subName } = props.item

  const processRules = (rules) => {
    return rules.map((rule) => {
      if (Object.keys(rule).includes("equal")) {
        return ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue(rule.equal) === value) {
              return Promise.resolve()
            }
            return Promise.reject(new Error(getSelectedLanguageContent(rule.message, props.selectedLanguage)))
          },
        })
      } else {
        return { ...rule, message: getSelectedLanguageContent(rule.message, props.selectedLanguage) }
      }
    })
  }

  return (
    <Form.List name={name} style={style} className={className} rules={rules && processRules(rules)}>
      {(fields, { add, remove }, { errors }) => (
        <Form.Item label={getSelectedLanguageContent(label, props.selectedLanguage)} required>
          {fields.map((field, index) => (
            <>
              <div
                className="d-flex justify-content-between"
                label={index === 0 && getSelectedLanguageContent(label, props.selectedLanguage)}
              > 
                <Form.Item name={[field.name, "id"]} className="d-none">
                  <Input />
                </Form.Item>
                <Form.Item
                  name={[field.name, subName]}
                  className="flex-fill"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message:
                        fields.length > 1 ? "Please input value or delete this field." : "Please input value.",
                    },
                  ]}
                >
                  <Input
                    size={inputConfig.size}
                    style={inputConfig.style}
                    className={inputConfig.className}
                    placeholder={getSelectedLanguageContent(placeholder, props.selectedLanguage)}
                  />
                </Form.Item>
                {fields.length > 1 ? (
                  <Button type="text" danger onClick={() => remove(field.name)}>
                    <DeleteOutlined />
                  </Button>
                ) : null}
              </div>
            </>
          ))}
          <Form.Item>
            <Button className="w-100" type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
              Add
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </Form.Item>
      )}
    </Form.List>
  )
}

export default FormList
