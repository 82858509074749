import React from "react"
import { Table, Form, InputNumber } from "antd"
import { handleNestedObjectKey } from "utils"

const BundleListOptions = (props) => {
  const { dataSource } = props.item

  return (
    <Table
      rowKey="id"
      className="mt-4"
      columns={[
        {
          title: "Image",
          render: (text, record) => (
            <img
              src={
                record.site_package_images[0]?.url ||
                props.data.ClientReducer.clientInfo.logo_image_url
              }
              style={{ width: "100px", height: "100px", objectFit: "contain" }}
            />
          ),
        },
        {
          title: "Code",
          dataIndex: "code",
        },
        {
          title: "Bundle Name",
          render: (text, record) => record.name.en,
        },
        {
          title: "Original Price",
          dataIndex: "price",
          render: (text, record) => `RM ${text.toFixed(2)}`,
        },
        {
          title: "Original Points",
          dataIndex: "points",
          render: (text, record) => `${text.toFixed(2)}PV`,
        },
        {
          title: "Unit Price",
          render: (text, record) => (
            <Form.Item
              className="mb-0"
              name={`bup_${record.id}`}
              rules={[{ required: true, message: "Please enter amount" }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          ),
        },
        {
          title: "Unit Points",
          render: (text, record) => (
            <Form.Item
              className="mb-0"
              name={`bupv_${record.id}`}
              rules={[{ required: true, message: "Please enter amount" }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          ),
        },
        {
          title: "Quantity",
          render: (text, record) => (
            <Form.Item
              className="mb-0"
              name={`bq_${record.id}`}
              rules={[{ required: true, message: "Please enter quantity" }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          ),
        },
        {
          title: "Total Price",
          render: (text, record) => (
            <Form.Item
              className="mb-0"
              name={`bp_${record.id}`}
              rules={[{ required: true, message: "Please enter amount" }]}
            >
              <InputNumber min={0} disabled />
            </Form.Item>
          ),
        },
        {
          title: "Total Points",
          render: (text, record) => (
            <Form.Item
              className="mb-0"
              name={`bpv_${record.id}`}
              rules={[{ required: true, message: "Please enter amount" }]}
            >
              <InputNumber min={0} disabled />
            </Form.Item>
          ),
        },
      ]}
      dataSource={handleNestedObjectKey(props, dataSource) || []}
    />
  )
}

export default BundleListOptions
