import React from "react"
import { Table, Form, InputNumber } from "antd"
import { handleNestedObjectKey } from "utils"

const ListOptions = (props) => {
  const { dataSource } = props.item

  return (
    <Table
      rowKey="id"
      className="mt-4"
      columns={[
        {
          title: "Image",
          render: (text, record) => (
            <img
              src={
                record.site_product_images[0]?.url ||
                props.data.ClientReducer.clientInfo.logo_image_url
              }
              style={{ width: "100px", height: "100px", objectFit: "contain" }}
            />
          ),
        },
        {
          title: "Product Code",
          dataIndex: "stock_code",
        },
        {
          title: "Product Name",
          render: (text, record) => record.site_product.name.en,
        },
        {
          title: "Variant 1",
          render: (text, record) => record.variant_level1?.name || "N/A",
        },
        {
          title: "Variant 2",
          render: (text, record) => record.variant_level2?.name || "N/A",
        },
        {
          title: "Original Price",
          dataIndex: "price_myr",
          render: (text, record) => `RM ${text.toFixed(2)}`,
        },
        {
          title: "Unit Price",
          render: (text, record) => (
            <Form.Item
              className="mb-0"
              name={`up_${record.id}`}
              rules={[{ required: true, message: "Please enter amount" }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          ),
        },
        {
          title: "Unit Points",
          render: (text, record) => (
            <Form.Item
              className="mb-0"
              name={`upv_${record.id}`}
              rules={[{ required: true, message: "Please enter amount" }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          ),
        },
        {
          title: "Quantity",
          render: (text, record) => (
            <Form.Item
              className="mb-0"
              name={`q_${record.id}`}
              rules={[{ required: true, message: "Please enter quantity" }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          ),
        },
        {
          title: "Total Price",
          render: (text, record) => (
            <Form.Item
              className="mb-0"
              name={`p_${record.id}`}
              rules={[{ required: true, message: "Please enter amount" }]}
            >
              <InputNumber min={0} disabled />
            </Form.Item>
          ),
        },
        {
          title: "Total Points",
          render: (text, record) => (
            <Form.Item
              className="mb-0"
              name={`pv_${record.id}`}
              rules={[{ required: true, message: "Please enter amount" }]}
            >
              <InputNumber min={0} disabled />
            </Form.Item>
          ),
        },
      ]}
      dataSource={handleNestedObjectKey(props, dataSource) || []}
    />
  )
}

export default ListOptions
