import { compose } from 'redux'

import WithHOC from './actions'
import BAForm from 'assets/pdf/LBB_BA Application Form_2024.pdf'
import CoreRenderFunction from 'DisplayCore'
import { ContactUsLayout } from 'PageLayout/LBB/ContactUs/ContactUs'
import { ContactUsSettingsLayout } from 'PageLayout/LBB/ContactUs/ContactUsSettings'

const LBBContactUs = props => {
  let {
    profile,
    selectedLanguage,

    // HOC
    onChangeHOC,
    addContent,
    updateContent,
    contentObj,
    contentString,
    showEditor
  } = props
  const { can_read } = props.modulePermissions

  const onClickAction = ( actionKey, ...args ) => {
    switch ( actionKey ){
      case 'CLICK_EDIT':
        onChangeHOC( 'showEditor', true )
        break
      case 'CANCEL_EDIT':
        onChangeHOC( 'showEditor', false )
        break
      case 'ON_CHANGE_CONTENT':
        onChangeHOC( 'contentString', args[ 0 ])
        break
      case 'UPDATE':
        let data
        if( contentObj.id > 0 ) {
          data = {
            content_json: contentObj[ 'content_json' ],
          }
          data[ 'content_json' ][ selectedLanguage ] = contentString
          updateContent( contentObj.id, data )
        } else {
          data = {
            page: 'contact-us',
            content_json: contentObj[ 'content_json' ],
          }
          data[ 'content_json' ][ selectedLanguage ] = contentString
          addContent( data )
        }
        break
      default: break
    }
  }

  const hideComponent = ( actionKey ) => {
    switch( actionKey ){
      case 'hideEditor':
        return !showEditor
      case 'hideContent':
        return showEditor
      case 'hideDownloadBAForm':
        return props.clientInfo.name !== "LBB"
      default: return false
    }
  }

  const renderContactUsLayout = () => {
    if ( profile.role_name?.toLowerCase().indexOf('admin') > -1 ) {
      return ContactUsSettingsLayout.content
    } else {
      return ContactUsLayout.content
    }
  }

  return(
    <>
      {
        can_read && (
          <CoreRenderFunction
            { ...props }
            BAForm={ BAForm }
            item={ renderContactUsLayout()}
            onClickAction={ onClickAction }
            hideComponent={ hideComponent }
          />
        )
      }
    </>
  )
}

export default compose(
  WithHOC
)( LBBContactUs )
